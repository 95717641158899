import { toast } from "react-hot-toast";
import { useCallback } from "react";
import callBackend from "../shared/backend";
import { useAuth } from "./auth";
import { AmazonMarketplace } from "../model/marketplace";
import { AmazonListing } from "../model/listing";
import { Activity, ActivityType } from "../model/activity";
import { PrepCenter, SupportedModel, Turnaround } from "../model/prepCenter";
import { Review } from "../model/review";

export type CallOptions = {
  toastError?: boolean;
  toastSuccess?: boolean;
};

export type CreateMarketplacesRequest = {
  type: string;
  amazon_region_id: string;
  amazon_marketplace_types: string[];
};

export type UpdateMarketplaceRequest = {
  repricing?: boolean;
};

export type RetrieveAmazonListingsRequest = {
  limit: number;
  offset: number;
  active: boolean | undefined;
};

export type CreatePrepCenterRequest = {
  claim: boolean;
  name: string;
  accepting_clients?: boolean | null;
  tagline?: string | null;
  description?: string | null;
  logo?: string | null;
  banner?: string | null;
  website?: string | null;
  phone?: string | null;
  email?: string | null;
  turnaround?: Turnaround | null;
  supported_models?: SupportedModel[] | null;
  liftgate_required?: boolean | null;
  linkedin?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  youtube?: string | null;
  location_country: string;
  location_state: string;
  location_city?: string | null;
  location_postal_code?: string | null;
  location_line1?: string | null;
  location_line2?: string | null;
};

export type UpdatePrepCenterRequest = {
  name?: string | null;
  accepting_clients?: boolean | null;
  tagline?: string | null;
  description?: string | null;
  logo?: string | null;
  banner?: string | null;
  website?: string | null;
  phone?: string | null;
  email?: string | null;
  turnaround?: Turnaround | null;
  supported_models?: SupportedModel[] | null;
  liftgate_required?: boolean | null;
  linkedin?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  youtube?: string | null;
};

export type CreatePrepCenterLocationRequest = {
  name?: string | null;
  country: string;
  state: string;
  city?: string | null;
  postal_code?: string | null;
  line1?: string | null;
  line2?: string | null;
};

export type UpdatePrepCenterLocationRequest = {
  name?: string | null;
  country?: string;
  state?: string;
  city?: string | null;
  postal_code?: string | null;
  line1?: string | null;
  line2?: string | null;
};

export type CreateReviewRequest = {
  prep_center_id: string;
  rating: number;
  review?: string | null;
};

export type UpdateReviewRequest = {
  rating?: number;
  review?: string | null;
};

export const useApi = () => {
  const { authUser, prepCenter } = useAuth();

  const callApiUnauthenticated = useCallback(
    async ({
      uri,
      method = "GET",
      params = {},
      data = {},
      options = {},
    }: {
      uri: string;
      method?: string;
      params?: { [key: string]: string };
      data?: object;
      options?: CallOptions;
    }): Promise<{ status: number; body?: any; errorMessage?: string }> => {
      if (params) {
        const queryParams = new URLSearchParams(params);
        uri = `${uri}?${queryParams.toString()}`;
      }
      try {
        const response = await callBackend({
          uri,
          method,
          data,
        });
        const status = response?.status;
        let body;
        let errorMessage;
        if (!response.ok) {
          errorMessage = await response.text();
          if (options.toastError) {
            toast(`Error: ${capitalizeError(errorMessage) as string}`);
          }
        } else {
          if (response.headers.get("content-type")) {
            body = await response.json();
          }
        }
        return { status, body, errorMessage: capitalizeError(errorMessage) };
      } catch (error) {
        if (error instanceof Error) {
          return {
            status: -1,
            body: undefined,
            errorMessage: capitalizeError(error.message),
          };
        } else {
          return { status: -1, body: undefined, errorMessage: undefined };
        }
      }
    },
    []
  );

  const callApi = useCallback(
    async ({
      uri,
      method = "GET",
      params = {},
      data = {},
      options = {},
      adminPrepCenterId,
    }: {
      uri: string;
      method?: string;
      params?: { [key: string]: string };
      data?: object;
      options?: CallOptions;
      adminPrepCenterId?: string;
    }): Promise<{ status: number; body?: any; errorMessage?: string }> => {
      if (!authUser) {
        throw new Error("must be authenticated to call api");
      }
      if (params) {
        const queryParams = new URLSearchParams(params);
        uri = `${uri}?${queryParams.toString()}`;
      }
      try {
        const response = await callBackend({
          uri,
          method,
          data,
          userId: authUser.id,
          prepCenterId: adminPrepCenterId ?? prepCenter?.id,
          accessToken: authUser.access_token,
        });
        const status = response?.status;
        let body;
        let errorMessage;
        if (!response.ok) {
          errorMessage = await response.text();
          if (options.toastError) {
            toast(`Error: ${capitalizeError(errorMessage) as string}`);
          }
        } else {
          if (response.headers.get("content-type")) {
            body = await response.json();
          }
        }
        return { status, body, errorMessage: capitalizeError(errorMessage) };
      } catch (error) {
        if (error instanceof Error) {
          return {
            status: -1,
            body: undefined,
            errorMessage: capitalizeError(error.message),
          };
        } else {
          return { status: -1, body: undefined, errorMessage: undefined };
        }
      }
    },
    [authUser, prepCenter]
  );

  const createMarketplaces = useCallback(
    async (
      request: CreateMarketplacesRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      marketplaces?: AmazonMarketplace[];
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApi({
        uri: "/marketplaces",
        method: "POST",
        data: request,
        options,
      });
      let marketplaces;
      if (status === 200) {
        marketplaces = body?.map(
          (marketplace: any) => new AmazonMarketplace(marketplace)
        );
        if (options?.toastSuccess) {
          toast("Successfully created marketplace.");
        }
      }
      return { status, marketplaces, errorMessage };
    },
    [callApi]
  );

  const updateMarketplace = useCallback(
    async (
      id: string,
      request: UpdateMarketplaceRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/marketplaces/${id}`,
        method: "PATCH",
        data: request,
        options,
      });
      if (status === 200 && options?.toastSuccess) {
        if (request.repricing) {
          toast("Enabled repricing.");
        } else {
          toast("Disabled repricing.");
        }
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const deleteMarketplace = useCallback(
    async (
      id: string,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/marketplaces/${id}`,
        method: "DELETE",
        options,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Marketplace removed.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const retrieveMarketplaces = useCallback(
    async (
      options?: CallOptions
    ): Promise<{
      status: number;
      marketplaces?: AmazonMarketplace[];
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApi({
        uri: "/marketplaces",
        method: "GET",
        options,
      });
      let marketplaces;
      if (status === 200) {
        marketplaces = body
          ?.map((marketplace: any) => new AmazonMarketplace(marketplace))
          .sort(
            (
              marketplace1: AmazonMarketplace,
              marketplace2: AmazonMarketplace
            ) => {
              return (
                marketplace1.created_at.getTime() -
                marketplace2.created_at.getTime()
              );
            }
          );
      }
      return { status, marketplaces, errorMessage };
    },
    [callApi]
  );

  const retrieveAmazonListings = useCallback(
    async (
      request: RetrieveAmazonListingsRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      listings?: AmazonListing[];
      total?: number;
      errorMessage?: string;
    }> => {
      const params: { [name: string]: string } = {
        limit: request.limit.toString(),
        offset: request.offset.toString(),
        type: "amazon",
      };
      if (request.active !== undefined) {
        params.active = String(request.active);
      }
      const { status, body, errorMessage } = await callApi({
        uri: "/listings",
        method: "GET",
        params,
        options,
      });
      let listings;
      let total;
      if (status === 200) {
        listings = body?.listings?.map(
          (listings: any) => new AmazonListing(listings)
        );
        total = body?.total;
      }
      return { status, listings, total, errorMessage };
    },
    [callApi]
  );

  /**
   * NEW BELOW
   */

  const listActivity = useCallback(
    async (
      options?: CallOptions
    ): Promise<{
      status: number;
      activityCollection?: Activity[];
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApiUnauthenticated({
        uri: "/activity",
        method: "GET",
        options,
      });
      let activityCollection;
      if (status === 200) {
        let prepCenters: Activity[] =
          body?.prep_centers?.map(
            (prepCenter: any) =>
              new Activity(ActivityType.PrepCenter, prepCenter)
          ) ?? [];
        let reviews: Activity[] =
          body?.reviews?.map(
            (review: any) => new Activity(ActivityType.Review, review)
          ) ?? [];
        activityCollection = [...prepCenters, ...reviews].sort(
          (activity1: Activity, activity2: Activity) => {
            return (
              activity2.object.created_at.getTime() -
              activity1.object.created_at.getTime()
            );
          }
        );
      }
      return { status, activityCollection, errorMessage };
    },
    [callApiUnauthenticated]
  );

  const listPrepCenters = useCallback(
    async (
      options?: CallOptions
    ): Promise<{
      status: number;
      prepCenters?: PrepCenter[];
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApiUnauthenticated({
        uri: "/prepceters",
        method: "GET",
        options,
      });
      let prepCenters;
      if (status === 200) {
        prepCenters = body
          ?.map((prepCenter: any) => new PrepCenter(prepCenter))
          .sort((prepCenter1: PrepCenter, prepCenter2: PrepCenter) => {
            return (
              prepCenter2.created_at.getTime() -
              prepCenter1.created_at.getTime()
            );
          });
      }
      return { status, prepCenters, errorMessage };
    },
    [callApiUnauthenticated]
  );

  const getPrepCenter = useCallback(
    async (
      id: string,
      options?: CallOptions
    ): Promise<{
      status: number;
      prepCenter?: PrepCenter;
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApiUnauthenticated({
        uri: `/prepceters/${id}`,
        method: "GET",
        options,
      });
      let prepCenter;
      if (status === 200) {
        prepCenter = new PrepCenter(body);
      }
      return { status, prepCenter, errorMessage };
    },
    [callApiUnauthenticated]
  );

  const createPrepCenter = useCallback(
    async (
      request: CreatePrepCenterRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      prepCenter?: PrepCenter;
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApi({
        uri: "/prepcenters",
        method: "POST",
        data: request,
        options,
      });
      let prepCenter;
      if (status === 200) {
        prepCenter = new PrepCenter(body);
        if (options?.toastSuccess) {
          toast("Successfully created prep center.");
        }
      }
      return { status, prepCenter, errorMessage };
    },
    [callApi]
  );

  const updatePrepCenter = useCallback(
    async (
      id: string,
      request: UpdatePrepCenterRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/prepcenters/${id}`,
        method: "PATCH",
        data: request,
        options,
        adminPrepCenterId: id,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Successfully updated prep center.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const createPrepCenterLocation = useCallback(
    async (
      request: CreatePrepCenterLocationRequest,
      options?: CallOptions,
      adminPrepCenterId?: string
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApi({
        uri: "/prepcenterlocations",
        method: "POST",
        data: request,
        options,
        adminPrepCenterId,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Successfully created prep center location.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const updatePrepCenterLocation = useCallback(
    async (
      id: string,
      request: UpdatePrepCenterLocationRequest,
      options?: CallOptions,
      adminPrepCenterId?: string
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/prepcenterlocations/${id}`,
        method: "PATCH",
        data: request,
        options,
        adminPrepCenterId,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Successfully updated prep center location.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const deletePrepCenterLocation = useCallback(
    async (
      id: string,
      options?: CallOptions,
      adminPrepCenterId?: string
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/prepcenterlocations/${id}`,
        method: "DELETE",
        options,
        adminPrepCenterId,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Prep center location removed.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const claimPrepCenter = useCallback(
    async (
      id: string,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/prepcenters/${id}/claim`,
        method: "POST",
        options,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Successfully claimed prep center.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const createReview = useCallback(
    async (
      request: CreateReviewRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      review?: Review;
      errorMessage?: string;
    }> => {
      const { status, body, errorMessage } = await callApi({
        uri: "/reviews",
        method: "POST",
        data: request,
        options,
      });
      let review;
      if (status === 200) {
        review = new Review(body);
        if (options?.toastSuccess) {
          toast("Successfully created prep center.");
        }
      }
      return { status, review, errorMessage };
    },
    [callApi]
  );

  const updateReview = useCallback(
    async (
      id: string,
      request: UpdateReviewRequest,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/reviews/${id}`,
        method: "PATCH",
        data: request,
        options,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Successfully updated review.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  const deleteReview = useCallback(
    async (
      id: string,
      options?: CallOptions
    ): Promise<{
      status: number;
      errorMessage?: string;
    }> => {
      const { status, errorMessage } = await callApi({
        uri: `/reviews/${id}`,
        method: "DELETE",
        options,
      });
      if (status === 200 && options?.toastSuccess) {
        toast("Review removed.");
      }
      return { status, errorMessage };
    },
    [callApi]
  );

  return {
    createMarketplaces,
    updateMarketplace,
    deleteMarketplace,
    retrieveMarketplaces,
    retrieveAmazonListings,
    listActivity,
    listPrepCenters,
    getPrepCenter,
    createPrepCenter,
    updatePrepCenter,
    createPrepCenterLocation,
    updatePrepCenterLocation,
    deletePrepCenterLocation,
    claimPrepCenter,
    createReview,
    updateReview,
    deleteReview,
  };
};

function capitalizeError(s?: string): string | undefined {
  return s ? s.charAt(0).toUpperCase() + s.slice(1) : undefined;
}
