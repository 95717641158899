export type MarketplaceType = {
  id: string;
  name: string;
  soon?: boolean;
};

export const MarketplaceTypes: MarketplaceType[] = [
  {
    id: "amazon",
    name: "Amazon",
  },
  {
    id: "walmart",
    name: "Walmart",
    soon: true,
  },
];

export type AmazonMarketplaceType = {
  id: string;
  amazon_id: string;
  name: string;
  url: string;
  countryCode: string;
  region: string;
};

export const AmazonMarketplaceTypes: AmazonMarketplaceType[] = [
  {
    id: "united_states",
    amazon_id: "ATVPDKIKX0DER",
    name: "United States",
    url: "amazon.com",
    countryCode: "us",
    region: "na",
  },
  {
    id: "canada",
    amazon_id: "A2EUQ1WTGCTBG2",
    name: "Canada",
    url: "amazon.ca",
    countryCode: "ca",
    region: "na",
  },
  {
    id: "mexico",
    amazon_id: "A1AM78C64UM0Y8",
    name: "Mexico",
    url: "amazon.com.mx",
    countryCode: "mx",
    region: "na",
  },
  {
    id: "brazil",
    amazon_id: "A2Q3Y263D00KWC",
    name: "Brazil",
    url: "amazon.com.br",
    countryCode: "br",
    region: "na",
  },
  {
    id: "spain",
    amazon_id: "A1RKKUPIHCS9HS",
    name: "Spain",
    url: "amazon.es",
    countryCode: "es",
    region: "eu",
  },
  {
    id: "united_kingdom",
    amazon_id: "A1F83G8C2ARO7P",
    name: "United Kingdom",
    url: "amazon.co.uk",
    countryCode: "gb",
    region: "eu",
  },
  {
    id: "france",
    amazon_id: "A13V1IB3VIYZZH",
    name: "France",
    url: "amazon.r",
    countryCode: "fr",
    region: "eu",
  },
  {
    id: "netherlands",
    amazon_id: "A1805IZSGTT6HS",
    name: "Netherlands",
    url: "amazon.nl",
    countryCode: "nl",
    region: "eu",
  },
  {
    id: "germany",
    amazon_id: "A1PA6795UKMFR9",
    name: "Germany",
    url: "amazon.de",
    countryCode: "de",
    region: "eu",
  },
  {
    id: "italy",
    amazon_id: "APJ6JRA9NG5V4",
    name: "Italy",
    url: "amazon.it",
    countryCode: "it",
    region: "eu",
  },
  {
    id: "sweden",
    amazon_id: "A2NODRKZP88ZB9",
    name: "Sweden",
    url: "amazon.se",
    countryCode: "se",
    region: "eu",
  },
  {
    id: "poland",
    amazon_id: "A1C3SOZRARQ6R3",
    name: "Poland",
    url: "amazon.pl",
    countryCode: "pl",
    region: "eu",
  },
  {
    id: "egypt",
    amazon_id: "ARBP9OOSHTCHU",
    name: "Egypt",
    url: "amazon.eg",
    countryCode: "eg",
    region: "eu",
  },
  {
    id: "turkey",
    amazon_id: "A33AVAJ2PDY3EV",
    name: "Turkey",
    url: "amazon.com.tr",
    countryCode: "tr",
    region: "eu",
  },
  {
    id: "saudi_arabia",
    amazon_id: "A17E79C6D8DWNP",
    name: "Saudi Arabia",
    url: "amazon.sa",
    countryCode: "sa",
    region: "eu",
  },
  {
    id: "united_arab_emirates",
    amazon_id: "A2VIGQ35RCS4UG",
    name: "United Arab Emirates",
    url: "amazon.ae",
    countryCode: "ae",
    region: "eu",
  },
  {
    id: "india",
    amazon_id: "A21TJRUUN4KGV",
    name: "India",
    url: "amazon.in",
    countryCode: "in",
    region: "eu",
  },
  {
    id: "singapore",
    amazon_id: "A19VAU5U5O7RUS",
    name: "Singapore",
    url: "amazon.sg",
    countryCode: "sg",
    region: "fe",
  },
  {
    id: "australia",
    amazon_id: "A39IBJ37TRP1C6",
    name: "Australia",
    url: "amazon.com.au",
    countryCode: "au",
    region: "fe",
  },
  {
    id: "japan",
    amazon_id: "A1VC38T7YXB528",
    name: "Japan",
    url: "amazon.co.jp",
    countryCode: "jp",
    region: "fe",
  },
];

export type AmazonRegionType = {
  id: string;
  name: string;
  marketplaces: AmazonMarketplaceType[];
};

export const AmazonRegionTypes = [
  {
    id: "na",
    name: "North America",
    marketplaces: AmazonMarketplaceTypes.filter(
      (marketplace) => marketplace.region === "na"
    ),
  },
  {
    id: "eu",
    name: "Europe",
    marketplaces: AmazonMarketplaceTypes.filter(
      (marketplace) => marketplace.region === "eu"
    ),
  },
  {
    id: "fe",
    name: "Far East",
    marketplaces: AmazonMarketplaceTypes.filter(
      (marketplace) => marketplace.region === "fe"
    ),
  },
];
