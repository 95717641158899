import { AmazonMarketplaceType, AmazonMarketplaceTypes } from "./amazon";
import { AmazonRegion } from "./region";

export type MarketplaceType = "amazon";

export type MarketplaceStatus = "healthy";

export class AmazonMarketplace {
  id: string;
  status: MarketplaceStatus;
  repricing: boolean;
  currencyCode: string;
  region: AmazonRegion;
  amazonMarketplaceType: AmazonMarketplaceType;
  listingsCount: number;
  created_at: Date;
  updated_at: Date;

  constructor(marketplace: any) {
    if (!marketplace) {
      throw new Error("error constructing marketplace");
    }
    this.id = marketplace?.id;
    this.amazonMarketplaceType = AmazonMarketplaceTypes.find(
      (amazonMarketplaceType) =>
        amazonMarketplaceType.id === marketplace?.amazon_marketplace_type
    ) as AmazonMarketplaceType;
    this.status = marketplace?.status;
    this.repricing = marketplace?.repricing;
    this.currencyCode = marketplace?.currencyCode;
    this.region = new AmazonRegion(marketplace?.region);
    this.listingsCount = marketplace?.listings_count ?? 0;
    this.created_at = new Date(marketplace?.created_at);
    this.updated_at = new Date(marketplace?.updated_at);
  }
}
