import { AmazonRegionType, AmazonRegionTypes } from "./amazon";

export type AmazonRegionStatus = "healthy" | "reauth";

export class AmazonRegion {
  id: string;
  status: AmazonRegionStatus;
  sellingPartnerId: string;
  amazonRegionType: AmazonRegionType;
  created_at: Date;
  updated_at: Date;

  constructor(amazonRegion: any) {
    if (!amazonRegion) {
      throw new Error("error constructing region");
    }
    this.id = amazonRegion?.id;
    this.status = amazonRegion?.status;
    this.sellingPartnerId = amazonRegion?.selling_partner_id;
    this.amazonRegionType = AmazonRegionTypes.find(
      (amazonRegionType) =>
        amazonRegionType.id === amazonRegion?.amazon_region_type
    ) as AmazonRegionType;
    this.created_at = new Date(amazonRegion?.created_at);
    this.updated_at = new Date(amazonRegion?.updated_at);
  }
}
