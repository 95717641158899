import { Dialog, Transition, Switch } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import {
  Fragment,
  ReactNode,
  useState,
  useCallback,
  useRef,
  ChangeEvent,
} from "react";
import PrimaryButton from "../buttons/primary";
import ErrorCallout from "../callouts/error";
import TextInput from "../input/text";
import NeutralModal from "./neutral";
import { toast } from "react-hot-toast";
import { useAuth } from "../../hooks/auth";
import { useApi } from "../../hooks/api";
import { PrepCenter, SupportedModel, Turnaround } from "../../model/prepCenter";
import classNames from "../../util/classNames";
import countries from "i18n-iso-countries";
import { useS3Upload } from "next-s3-upload";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const supportedModelDetails = [
  {
    value: SupportedModel.SupportedModelWholesale,
    title: "Wholesale",
    description: "High unit volume, often palletized.",
  },
  {
    value: SupportedModel.SupportedModelPrivateLabel,
    title: "Private label",
    description: "High unit volume, often palletized.",
  },
  {
    value: SupportedModel.SupportedModelOnlineArbitrage,
    title: "Online arbitrage",
    description:
      "Variety of products, ordered online and shipped to the center.",
  },
  {
    value: SupportedModel.SupportedModelRetailArbitrage,
    title: "Retail arbitrage",
    description: "Variety of products.",
  },
  {
    value: SupportedModel.SupportedModelHazmat,
    title: "Hazmat",
    description:
      "Dangerous goods that require the seller to have authorization for.",
  },
  {
    value: SupportedModel.SupportedModelBooks,
    title: "Books",
    description: "New and used book prep.",
  },
];

const turnaroundTimes = [
  {
    value: Turnaround.TurnaroundUnknown,
    title: "Unknown",
  },
  {
    value: Turnaround.Turnaround24,
    title: "Within 24 hours",
  },
  {
    value: Turnaround.Turnaround24to48,
    title: "24 to 48 hours",
  },
  {
    value: Turnaround.Turnaround48to72,
    title: "48 to 72 hours",
  },
  {
    value: Turnaround.Turnaround72plus,
    title: "Over 72 hours",
  },
];

export default function CreatePrepCenterModal({
  open,
  setOpen,
  onCreate,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreate?: (prepCenter?: PrepCenter) => void;
}) {
  const formRef: React.RefObject<HTMLFormElement> = useRef(null);
  const { authUser, dbUser, prepCenter, updateUser } = useAuth();
  const { createPrepCenter } = useApi();
  const [fieldsRequired, setFieldsRequired] = useState(false);
  const [fieldValidity, setFieldValidity] = useState<{
    [key: string]: boolean;
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { uploadToS3, files } = useS3Upload();

  const [claim, setClaim] = useState(false);
  const [name, setName] = useState("");
  const [acceptingClients, setAcceptingClients] = useState(true);
  const [tagline, setTagline] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState<string | null>(null);
  const [banner, setBanner] = useState<string | null>(null);
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [turnaround, setTurnaround] = useState<Turnaround>(
    Turnaround.TurnaroundUnknown
  );
  const [supportedModels, setSupportedModels] = useState<{
    [key: string]: boolean;
  }>({});
  const [liftgateRequired, setLiftgateRequired] = useState(false);
  const [linkedin, setLinkedin] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [locationCountry, setLocationCountry] = useState("US");
  const [locationState, setLocationState] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [locationPostalCode, setLocationPostalCode] = useState("");
  const [locationLine1, setLocationLine1] = useState("");
  const [locationLine2, setLocationLine2] = useState("");

  const handleLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const { url } = await uploadToS3(file);
    setLogo(url);
  };

  const handleBannerChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const { url } = await uploadToS3(file);
    setBanner(url);
  };

  const handleClear = useCallback(async () => {
    // Reset fields
    setClaim(false);
    setName("");
    setAcceptingClients(true);
    setTagline("");
    setDescription("");
    setLogo(null);
    setBanner(null);
    setWebsite("");
    setPhone("");
    setEmail("");
    setTurnaround(Turnaround.TurnaroundUnknown);
    setSupportedModels({});
    setLiftgateRequired(false);
    setLinkedin("");
    setFacebook("");
    setInstagram("");
    setTwitter("");
    setYoutube("");
    setLocationCountry("US");
    setLocationState("");
    setLocationCity("");
    setLocationPostalCode("");
    setLocationLine1("");
    setLocationLine2("");

    // Reset required field validation
    setFieldsRequired(false);
    setFieldValidity({});
  }, [
    setClaim,
    setName,
    setAcceptingClients,
    setTagline,
    setDescription,
    setLogo,
    setBanner,
    setWebsite,
    setPhone,
    setEmail,
    setTurnaround,
    setSupportedModels,
    setLiftgateRequired,
    setLinkedin,
    setFacebook,
    setInstagram,
    setTwitter,
    setYoutube,
    setLocationCountry,
    setLocationState,
    setLocationCity,
    setLocationPostalCode,
    setLocationLine1,
    setLocationLine2,
    setFieldsRequired,
    setFieldValidity,
  ]);

  const handleModal = useCallback(async () => {
    if (!name || !locationState) {
      setFieldsRequired(true);
      formRef?.current?.scrollIntoView({ behavior: "smooth" });
      throw new Error("Name, country, and state are required fields.");
    }
    if (Object.values(fieldValidity).includes(false)) {
      formRef?.current?.scrollIntoView({ behavior: "smooth" });
      throw new Error(
        `Invalid fields: ${Object.entries(fieldValidity)
          .filter(([_, v]) => !v)
          .map(([k, _]) => k)
          .join(", ")}.`
      );
    }

    let { errorMessage, prepCenter } = await createPrepCenter({
      claim,
      name,
      accepting_clients: acceptingClients,
      tagline,
      description,
      logo,
      banner,
      website,
      phone,
      email,
      turnaround,
      supported_models: Object.entries(supportedModels)
        .filter(([_, v]) => v)
        .map(([k, _]) => k as SupportedModel),
      liftgate_required: liftgateRequired,
      linkedin,
      facebook,
      instagram,
      twitter,
      youtube,
      location_country: locationCountry,
      location_state: locationState,
      location_city: locationCity,
      location_postal_code: locationPostalCode,
      location_line1: locationLine1,
      location_line2: locationLine2,
    });
    if (errorMessage) {
      toast(`Error: ${errorMessage}`);
      formRef?.current?.scrollIntoView({ behavior: "smooth" });
      throw new Error(errorMessage);
    } else {
      toast("Added prep center.");
      if (onCreate) onCreate(prepCenter);
    }
  }, [
    name,
    locationState,
    fieldValidity,
    createPrepCenter,
    claim,
    acceptingClients,
    tagline,
    description,
    logo,
    banner,
    website,
    phone,
    email,
    turnaround,
    supportedModels,
    liftgateRequired,
    linkedin,
    facebook,
    instagram,
    twitter,
    youtube,
    locationCountry,
    locationCity,
    locationPostalCode,
    locationLine1,
    locationLine2,
    onCreate,
  ]);

  return (
    authUser && (
      <NeutralModal
        open={open}
        setOpen={setOpen}
        title="Add a prep center"
        description="This prep center will be listed in our directory for others to find and review."
        saveText="Add prep center"
        handle={handleModal}
        clear={handleClear}
      >
        <code className="hidden text-gray-700 whitespace-pre">{`
        claim: ${JSON.stringify(claim)}
        name: ${JSON.stringify(name)}
        acceptingClients: ${JSON.stringify(acceptingClients)}
        tagline: ${JSON.stringify(tagline)}
        description: ${JSON.stringify(description)}
        logo: ${JSON.stringify(logo)}
        banner: ${JSON.stringify(banner)}
        website: ${JSON.stringify(website)}
        phone: ${JSON.stringify(phone)}
        email: ${JSON.stringify(email)}
        turnaround: ${JSON.stringify(turnaround)}
        supportedModels: ${JSON.stringify(supportedModels)}
        liftgateRequired: ${JSON.stringify(liftgateRequired)}
        linkedin: ${JSON.stringify(linkedin)}
        facebook: ${JSON.stringify(facebook)}
        instagram: ${JSON.stringify(instagram)}
        twitter: ${JSON.stringify(twitter)}
        youtube: ${JSON.stringify(youtube)}
        locationCountry: ${JSON.stringify(locationCountry)}
        locationState: ${JSON.stringify(locationState)}
        locationCity: ${JSON.stringify(locationCity)}
        locationPostalCode: ${JSON.stringify(locationPostalCode)}
        locationLine1: ${JSON.stringify(locationLine1)}
        locationLine2: ${JSON.stringify(locationLine2)}
        -
        fieldValidity: ${JSON.stringify(fieldValidity)}
      `}</code>

        <form className="space-y-8 divide-y divide-gray-200" ref={formRef}>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name*
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required={fieldsRequired}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="ACME Prep"
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md
                      required:invalid:border-[#F84444] required:invalid:focus:border-[#F84444]"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="tagline"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tagline
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="tagline"
                      id="tagline"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                      placeholder="We're the best in prep."
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Here's a little snippet about how we're the best in prepping your inventory."
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Write a few sentences about the prep center.
                  </p>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="logo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Logo
                  </label>
                  <div className="mt-1">
                    <input
                      className="p-2 block w-full sm:text-sm text-gray-500 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none"
                      id="logo"
                      type="file"
                      onChange={handleLogoChange}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="banner"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Cover photo
                  </label>
                  <div className="mt-1">
                    <input
                      className="p-2 block w-full sm:text-sm text-gray-500 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none"
                      id="banner"
                      type="file"
                      onChange={handleBannerChange}
                    />
                  </div>
                </div>

                {files &&
                files.length &&
                files[files.length - 1].progress !== 100 ? (
                  <div className="sm:col-span-6">
                    <div className="-mt-4">
                      <p className="text-sm text-gray-500">
                        {files[files.length - 1].progress}% uploaded.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Website
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      pattern="^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,24}$"
                      name="website"
                      id="website"
                      value={website}
                      onChange={(e) => {
                        setWebsite(e.target.value);
                        setFieldValidity((fieldValidity) => ({
                          ...fieldValidity,
                          [e.target.name]: e.target.validity.valid,
                        }));
                      }}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md
                      invalid:border-[#F84444] invalid:focus:border-[#F84444]"
                      placeholder="www.acmeprep.com"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone
                  </label>
                  <div className="mt-1">
                    <input
                      type="tel"
                      pattern="^\+?((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$"
                      name="phone"
                      id="phone"
                      autoComplete="phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setFieldValidity((fieldValidity) => ({
                          ...fieldValidity,
                          [e.target.name]: e.target.validity.valid,
                        }));
                      }}
                      placeholder="1 (234) 567-8910"
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md
                      invalid:border-[#F84444] invalid:focus:border-[#F84444]"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setFieldValidity((fieldValidity) => ({
                          ...fieldValidity,
                          [e.target.name]: e.target.validity.valid,
                        }));
                      }}
                      placeholder="acme@prep.com"
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md
                      invalid:border-[#F84444] invalid:focus:border-[#F84444]"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <fieldset>
                  <legend className="sr-only">Supported business models</legend>
                  <div
                    className="text-base font-medium text-gray-900"
                    aria-hidden="true"
                  >
                    Supported business models
                  </div>
                  <p className="text-sm text-gray-500">
                    The types of products allowed to be sent into this prep
                    center.
                  </p>
                  <div className="mt-4 space-y-4">
                    {supportedModelDetails.map((supportedModelDetail) => (
                      <div
                        key={supportedModelDetail.value}
                        className="relative flex items-start"
                      >
                        <div className="flex items-center h-5">
                          <input
                            id={supportedModelDetail.value}
                            name={supportedModelDetail.value}
                            type="checkbox"
                            checked={
                              supportedModels[supportedModelDetail.value] ||
                              false
                            }
                            onChange={(e) =>
                              setSupportedModels((supportedModels) => ({
                                ...supportedModels,
                                [supportedModelDetail.value]: e.target.checked,
                              }))
                            }
                            className="focus:ring-vendrive-500 h-4 w-4 text-vendrive-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={supportedModelDetail.value}
                            className="font-medium text-gray-700"
                          >
                            {supportedModelDetail.title}
                          </label>
                          {/*<p className="text-gray-500">
                          {supportedModelDetail.description}
                        </p>*/}
                        </div>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="turnaround"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Turnaround time
                  </label>
                  <div className="mt-1">
                    <select
                      id="turnaround"
                      name="turnaround"
                      value={turnaround}
                      onChange={(e) =>
                        setTurnaround(e.target.value as Turnaround)
                      }
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      {turnaroundTimes.map((turnaroundTime) => (
                        <option
                          key={turnaroundTime.value}
                          value={turnaroundTime.value}
                        >
                          {turnaroundTime.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex-grow flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Accepting clients
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        Is this prep center actively taking on new clients i.e.
                        sellers?
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={acceptingClients}
                      onChange={setAcceptingClients}
                      className={classNames(
                        acceptingClients ? "bg-vendrive-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vendrive-500"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          acceptingClients ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>

                <div className="sm:col-span-6">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex-grow flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Liftgate required
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        Does this prep center require a lift gate for freight
                        that arrives on a pallet?
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={liftgateRequired}
                      onChange={setLiftgateRequired}
                      className={classNames(
                        liftgateRequired ? "bg-vendrive-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vendrive-500"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          liftgateRequired ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Social profiles
                </h3>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="facebook"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Facebook
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      @
                    </span>
                    <input
                      type="text"
                      name="facebook"
                      id="facebook"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      className="focus:ring-vendrive-500 focus:border-vendrive-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="acmeprep"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="instagram"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Instagram
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      @
                    </span>
                    <input
                      type="text"
                      name="instagram"
                      id="instagram"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      className="focus:ring-vendrive-500 focus:border-vendrive-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="acmeprep"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="linkedin"
                    className="block text-sm font-medium text-gray-700"
                  >
                    LinkedIn
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      @
                    </span>
                    <input
                      type="text"
                      name="linkedin"
                      id="linkedin"
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                      className="focus:ring-vendrive-500 focus:border-vendrive-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="acmeprep"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="twitter"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Twitter
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      @
                    </span>
                    <input
                      type="text"
                      name="twitter"
                      id="twitter"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      className="focus:ring-vendrive-500 focus:border-vendrive-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="acmeprep"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="youtube"
                    className="block text-sm font-medium text-gray-700"
                  >
                    YouTube
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      @
                    </span>
                    <input
                      type="text"
                      name="youtube"
                      id="youtube"
                      value={youtube}
                      onChange={(e) => setYoutube(e.target.value)}
                      className="focus:ring-vendrive-500 focus:border-vendrive-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                      placeholder="acmeprep"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Location
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Where inventory is shipped to be prepped.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country*
                  </label>
                  <div className="mt-1">
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      value={locationCountry}
                      onChange={(e) => setLocationCountry(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      {Object.entries(
                        countries.getNames("en", { select: "official" })
                      ).map(([countryCode, country]) => (
                        <option value={countryCode} key={countryCode}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address line 1
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      value={locationLine1}
                      onChange={(e) => setLocationLine1(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address line 2
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      value={locationLine2}
                      onChange={(e) => setLocationLine2(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      value={locationCity}
                      onChange={(e) => setLocationCity(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province*
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      required={fieldsRequired}
                      value={locationState}
                      onChange={(e) => setLocationState(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md
                      required:invalid:border-[#F84444] required:invalid:focus:border-[#F84444]"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      value={locationPostalCode}
                      onChange={(e) => setLocationPostalCode(e.target.value)}
                      className="shadow-sm focus:ring-vendrive-500 focus:border-vendrive-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Is this your prep center?
                </h3>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex-grow flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Claim prep center
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        You&apos;ll be able to add more locations and make
                        changes.
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={claim}
                      onChange={setClaim}
                      className={classNames(
                        claim ? "bg-vendrive-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vendrive-500"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          claim ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            </div>
          </div>
        </form>
      </NeutralModal>
    )
  );
}
