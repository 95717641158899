import { useCallback, useEffect, useState, Fragment, SVGProps } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "../util/classNames";
import { PrepCenter, PrepCenterLocation } from "../model/prepCenter";
import { useApi } from "../hooks/api";
import { useSearchKeyboardEvents } from "../hooks/useSearchKeyboardEvents";
import Spinner from "./spinner";
import ErrorCallout from "./callouts/error";
import fuzzysort from "fuzzysort";

function SearchIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg aria-hidden="true" viewBox="0 0 20 20" {...props}>
      <path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z" />
    </svg>
  );
}

const getLocationInfo = (locations: PrepCenterLocation[] | null): string => {
  if (!locations || locations.length === 0) return "No locations listed";

  if (locations.length === 1)
    return `${locations[0].city ? locations[0].city + ", " : ""}${
      locations[0].state
    }, ${locations[0].country}`;

  return `${locations.length} Location${locations.length > 1 ? "s" : ""}`;
};

export default function Search(baseProps?: JSX.IntrinsicElements["div"]) {
  const { listPrepCenters } = useApi();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [modifierKey, setModifierKey] = useState<string>("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [prepCenters, setPrepCenters] = useState<PrepCenter[]>([]);
  const [prepCentersError, setPrepCentersError] = useState<string | null>(null);

  useEffect(() => {
    async function doListPrepCenters() {
      setLoading(true);
      const { status, prepCenters, errorMessage } = await listPrepCenters();
      if (status === 200) {
        setPrepCenters(prepCenters as PrepCenter[]);
      } else {
        setPrepCentersError(errorMessage as string);
      }
      setLoading(false);
    }
    if (open) doListPrepCenters();
  }, [listPrepCenters, setLoading, setPrepCenters, open]);

  const filteredPrepCenters = fuzzysort
    .go(query, prepCenters, { key: "name" })
    .map((result) => result.obj);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
    setPrepCentersError(null);
  }, [setOpen]);

  useSearchKeyboardEvents({ isOpen: open, onOpen, onClose });

  useEffect(() => {
    setModifierKey(
      /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "⌘" : "Ctrl "
    );
  }, []);

  return (
    <>
      <button
        type="button"
        className={classNames(
          "group ml-auto lg:ml-0 text-vendrive-200 lg:text-white hover:text-white justify-start flex lg:flex-1 items-center lg:max-w-xs rounded-md lg:rounded-lg p-2 lg:px-4 lg:py-2 text-base bg-vendrive-base lg:bg-vendrive-500 hover:bg-vendrive-500 lg:bg-opacity-60 hover:bg-opacity-75 lg:hover:bg-opacity-70 overflow-hidden",
          baseProps?.className ? baseProps.className : ""
        )}
        onClick={onOpen}
      >
        <SearchIcon className="h-6 w-6 lg:h-5 lg:w-5 flex-none fill-vendrive-200 lg:fill-white group-hover:fill-white" />
        <span className="hidden lg:block ml-2 truncate">
          Search prep centers
        </span>
        <kbd className="hidden lg:block ml-auto pl-2 font-medium text-white/70">
          <kbd className="font-sans">{modifierKey}</kbd>
          <kbd className="font-sans">K</kbd>
        </kbd>
      </button>

      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex flex-col mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-vendrive-base ring-opacity-5 transition-all max-h-full">
                <Combobox
                  onChange={(id) => {
                    router.push(`/p/${id}`);
                    onClose();
                  }}
                  value=""
                >
                  <div className="relative">
                    <SearchIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 fill-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search prep centers..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {filteredPrepCenters.length > 0 && (
                    <Combobox.Options
                      static
                      className="max-h-96 scroll-py-3 overflow-y-auto p-3"
                    >
                      {filteredPrepCenters.map((prepCenter) => (
                        <Combobox.Option
                          key={prepCenter.id}
                          value={prepCenter.id}
                          className={({ active }) =>
                            classNames(
                              "flex cursor-pointer select-none rounded-xl p-3 items-center",
                              active ? "bg-gray-100" : ""
                            )
                          }
                        >
                          {({ active }) => (
                            <>
                              <div
                                className={classNames(
                                  "flex h-10 w-10 shrink-0 items-center justify-center text-black rounded-md",
                                  prepCenter.logo
                                    ? "p-1 bg-gray-100"
                                    : "bg-gray-300 p-2.5"
                                )}
                              >
                                <div className="relative flex h-full w-full rounded-sm">
                                  <Image
                                    src={
                                      prepCenter.logo ??
                                      "/images/icon-light.svg"
                                    }
                                    alt={prepCenter.name}
                                    layout="fill"
                                    objectFit="contain"
                                    className="rounded-sm"
                                  />
                                </div>
                              </div>
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">
                                  {prepCenter.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {getLocationInfo(prepCenter.locations)}
                                </p>
                              </div>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {loading && query !== "" && (
                    <div className="py-8 px-6 text-center text-sm sm:px-14 overflow-y-auto">
                      <Spinner className="mr-2.5 fill-slate-300 w-10 h-10 z-10"></Spinner>
                    </div>
                  )}

                  {!loading && prepCentersError && (
                    <ErrorCallout subTitle={prepCentersError}></ErrorCallout>
                  )}

                  {!loading &&
                    query !== "" &&
                    filteredPrepCenters.length === 0 && (
                      <div className="py-14 px-6 text-center text-sm sm:px-14 overflow-y-auto">
                        <ExclamationCircleIcon
                          type="outline"
                          name="exclamation-circle"
                          className="mx-auto h-6 w-6 text-gray-400"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                          No results found
                        </p>
                        <p className="mt-2 text-gray-500">
                          No prep centers found for this search term. Please try
                          again.
                        </p>
                      </div>
                    )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
