import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, ReactNode, useState } from "react";
import classNames from "../../util/classNames";
import PrimaryButton from "../buttons/primary";
import ErrorCallout from "../callouts/error";

export default function NeutralModal({
  open,
  setOpen,
  title,
  description,
  cancelText = "Cancel",
  saveText = "Save",
  saveDisabled = false,
  disabledText = "Saved",
  handle,
  clear,
  children,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  cancelText?: string;
  saveText?: string;
  saveDisabled?: boolean;
  disabledText?: string;
  handle: () => void;
  clear: () => void;
  children: ReactNode;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function closeModal() {
    setOpen(false);
    setErrorMessage(null);
    clear();
  }

  async function handleButton() {
    try {
      await handle();
      closeModal();
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[768px] transform overflow-hidden rounded-xl bg-white shadow-xl transition-all">
                {/* Header */}
                <div className="bg-gray-50 py-7 px-10">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="m-10">
                  {errorMessage ? (
                    <div className="mb-10">
                      <ErrorCallout subTitle={errorMessage}></ErrorCallout>
                    </div>
                  ) : null}
                  {children}
                </div>
                <div className="bg-gray-50 flex px-10 h-20 justify-end items-center">
                  <button
                    className="mr-5 text-sm text-gray-700 hover:text-gray-900"
                    onClick={closeModal}
                  >
                    {cancelText}
                  </button>
                  <PrimaryButton
                    disabled={saveDisabled}
                    className={saveDisabled ? "opacity-20" : ""}
                    onClick={handleButton}
                  >
                    <p>{saveDisabled ? disabledText : saveText}</p>
                  </PrimaryButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
