import { PrepCenter } from "./prepCenter";
import { Review } from "./review";

export enum ActivityType {
  PrepCenter = "PrepCenter",
  Review = "Review",
}

export class Activity {
  type: ActivityType;
  object: PrepCenter | Review;

  constructor(type: ActivityType, object: any) {
    if (!object) {
      throw new Error("error constructing activity");
    }
    this.type = type;
    this.object =
      type === ActivityType.PrepCenter
        ? new PrepCenter(object)
        : new Review(object);
  }
}
