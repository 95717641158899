import { ReactNode, useCallback, useState } from "react";
import Spinner from "../spinner";
import classNames from "../../util/classNames";
import { ExternalLinkIcon, PlusIcon } from "@heroicons/react/outline";

export default function Button({
  children,
  external = false,
  plus = false,
  disabled = false,
  className = "",
  onClick,
}: {
  children: ReactNode;
  external?: boolean;
  plus?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        await onClick();
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [loading, setLoading, onClick]
  );

  return (
    <button
      disabled={disabled}
      className={classNames(
        "justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-vendrive-600 hover:bg-vendrive-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-vendrive-500",
        className
      )}
      onClick={handleClick}
    >
      <div className="flex justify-center items-center">
        {loading ? (
          <Spinner className="mr-2.5 fill-white w-5 h-5"></Spinner>
        ) : plus ? (
          <PlusIcon className="text-white w-4 h-4 mr-3"></PlusIcon>
        ) : null}
        {children}
        {external ? (
          <ExternalLinkIcon className="text-white w-5 h-5 ml-3"></ExternalLinkIcon>
        ) : null}
      </div>
    </button>
  );
}
