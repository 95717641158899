import { navItem } from "aws-amplify";
import { AmazonMarketplaceType, AmazonMarketplaceTypes } from "./amazon";

export type ListingType = "amazon";

export type Condition =
  | "new_new"
  | "new_open_box"
  | "new_oem"
  | "refurbished_refurbished"
  | "used_like_new"
  | "used_very_good"
  | "used_good"
  | "used_acceptable"
  | "collectible_like_new"
  | "collectible_very_good"
  | "collectible_good"
  | "collectible_acceptable"
  | "club_club";

export type FulfillmentType = "fba" | "mf";

export type Image = {
  url: string;
  height: number;
  width: number;
};

export type Quantity = {
  snl: number | null;
  mf: number | null;
  totalFba: number | null;
  fulfillableFba: number;
  fbaBreakdown: {
    total: number;
    fulfillable: number;
    inbound: {
      working: number;
      shipped: number;
      receiving: number;
    };
    reserved: {
      total: number;
      pendingCustomerOrder: number;
      pendingTransShipment: number;
      fcProcessing: number;
    };
    researching: {
      total: number;
      shortTerm: number;
      midTerm: number;
      longTerm: number;
    };
    unfulfillable: {
      total: number;
      customerDamaged: number;
      warehouseDamaged: number;
      distributorDamaged: number;
      carrierDamaged: number;
      defective: number;
      expired: number;
    };
  };
};

export type Fee = {
  amount: number;
  tax: number;
  promotion: number;
  final: number;
};

export type Fees = {
  priceUsedForSnl: number;
  totalSnl: number;
  snlBreakdown: {
    referral: number;
    fbaFulfillment: number;
  };
  priceUsedForCore: number;
  totalCore: number;
  coreBreakdown: {
    referral: Fee;
    fbaFulfillment: Fee;
    perItem: Fee;
    closing: Fee;
  };
};

export type BuyBox = {
  price: number;
  shipping: number;
  sellerId: string | null;
  isFba: boolean | null;
  isPrime: boolean | null;
  isNationalPrime: boolean | null;
  feedbackCount: number | null;
  feedbackRating: number | null;
  shippingAvailabilityType:
    | "NOW"
    | "FUTURE_WITH_DATE"
    | "FUTURE_WITHOUT_DATE"
    | null;
  shippingAvailabilityTime: Date | null;
  shipFromCountry: string | null;
};

export type BSR = {
  id: string;
  title: string;
  rank: number;
};

export class AmazonListing {
  id: string;
  type: ListingType;
  marketplaceId: string;
  name: string;
  sku: string;
  currencyCode: string;
  minPrice: number | null;
  maxPrice: number | null;
  cost: number | null;
  mapPrice: number | null;
  manualPrice: number | null;
  currentPrice: number | null;
  currentShipping: number | null;
  repricing: boolean;
  note: string | null;
  created_at: Date;
  updated_at: Date;
  amazonRegionId: string;
  amazonMarketplaceType: AmazonMarketplaceType;
  asin: string;
  condition: Condition;
  fulfillmentType: FulfillmentType;
  active: boolean;
  buyable: boolean | null;
  discoverable: boolean | null;
  detailPageRemoved: boolean | null;
  image: Image | null;
  enrolledInSnl: boolean;
  quantity: Quantity;
  fees: Fees;
  isBuyBoxWinner: boolean | null;
  isFeaturedMerchant: boolean | null;
  offerPosition: number | null;
  hasOffers: boolean | null;
  hasNewBuyBox: boolean | null;
  hasUsedBuyBox: boolean | null;
  newBuyBox: BuyBox | null;
  usedBuyBox: BuyBox | null;
  competitivePriceThreshold: number | null;
  listPrice: number | null;
  suggestedLowerPricePlusShipping: number | null;
  classification: BSR | null;
  displayGroup: BSR | null;

  constructor(listing: any) {
    if (!listing) {
      throw new Error("error constructing listing");
    }
    this.id = listing?.id;
    this.type = listing?.type;
    this.marketplaceId = listing?.marketplace_id;
    this.name = listing?.name;
    this.sku = listing?.sku;
    this.currencyCode = listing?.currency_code;
    this.minPrice = listing?.min_price ?? null;
    this.maxPrice = listing?.max_price ?? null;
    this.cost = listing?.cost ?? null;
    this.mapPrice = listing?.map_price ?? null;
    this.manualPrice = listing?.manual_price ?? null;
    this.currentPrice = listing?.current_price ?? null;
    this.currentShipping = listing?.current_shipping ?? null;
    this.repricing = listing?.repricing;
    this.note = listing?.note ?? null;
    this.created_at = new Date(listing?.created_at);
    this.updated_at = new Date(listing?.updated_at);
    this.amazonRegionId = listing?.amazon_region_id;
    this.amazonMarketplaceType = AmazonMarketplaceTypes.find(
      (amazonMarketplaceType) =>
        amazonMarketplaceType.id === listing?.amazon_marketplace_type
    ) as AmazonMarketplaceType;
    this.asin = listing?.asin;
    this.condition = listing?.condition;
    this.fulfillmentType = listing?.fulfillment_type;
    this.active = listing?.active;
    this.buyable = listing?.buyable ?? null;
    this.discoverable = listing?.discoverable ?? null;
    this.detailPageRemoved = listing?.detail_page_removed ?? null;
    this.image = listing?.image?.url
      ? {
          url: listing?.image?.url,
          height: listing?.image?.height,
          width: listing?.image?.width,
        }
      : null;
    this.enrolledInSnl = listing?.enrolled_in_snl;
    this.quantity = {
      mf: listing?.quantity?.mf ?? null,
      snl: listing?.quantity?.snl ?? null,
      totalFba: listing?.quantity?.total_fba ?? null,
      fulfillableFba: listing?.quantity?.fulfillable_fba,
      fbaBreakdown: {
        total: listing?.quantity?.fba_breakdown?.total,
        fulfillable: listing?.quantity?.fba_breakdown?.fulfillable,
        inbound: {
          working: listing?.quantity?.fba_breakdown?.inbound?.working,
          shipped: listing?.quantity?.fba_breakdown?.inbound?.shipped,
          receiving: listing?.quantity?.fba_breakdown?.inbound?.receiving,
        },
        reserved: {
          total: listing?.quantity?.fba_breakdown?.reserved?.total,
          pendingCustomerOrder:
            listing?.quantity?.fba_breakdown?.reserved?.pending_customer_order,
          pendingTransShipment:
            listing?.quantity?.fba_breakdown?.reserved?.pending_trans_shipment,
          fcProcessing:
            listing?.quantity?.fba_breakdown?.reserved?.fc_processing,
        },
        researching: {
          total: listing?.quantity?.fba_breakdown?.researching?.total,
          shortTerm: listing?.quantity?.fba_breakdown?.researching?.short_term,
          midTerm: listing?.quantity?.fba_breakdown?.researching?.mid_term,
          longTerm: listing?.quantity?.fba_breakdown?.researching?.long_term,
        },
        unfulfillable: {
          total: listing?.quantity?.fba_breakdown?.unfulfillable?.total,
          customerDamaged:
            listing?.quantity?.fba_breakdown?.unfulfillable?.customer_damaged,
          warehouseDamaged:
            listing?.quantity?.fba_breakdown?.unfulfillable?.warehouse_damaged,
          distributorDamaged:
            listing?.quantity?.fba_breakdown?.unfulfillable
              ?.distributor_damaged,
          carrierDamaged:
            listing?.quantity?.fba_breakdown?.unfulfillable?.carrier_damaged,
          expired: listing?.quantity?.fba_breakdown?.unfulfillable?.expired,
          defective: listing?.quantity?.fba_breakdown?.unfulfillable?.defective,
        },
      },
    };
    this.fees = {
      priceUsedForSnl: listing?.fees?.price_used_for_snl,
      totalSnl: listing?.fees?.total_snl,
      snlBreakdown: {
        referral: listing?.fees?.snl_breakdown?.referral_fee,
        fbaFulfillment: listing?.fees?.snl_breakdown?.fba_fulfillment_fee,
      },
      priceUsedForCore: listing?.fees?.price_used_for_core,
      totalCore: listing?.fees?.total_core,
      coreBreakdown: {
        referral: {
          amount: listing?.fees?.core_breakdown?.referral_fee?.amount,
          tax: listing?.fees?.core_breakdown?.referral_fee?.tax,
          promotion: listing?.fees?.core_breakdown?.referral_fee?.promotion,
          final: listing?.fees?.core_breakdown?.referral_fee?.final,
        },
        fbaFulfillment: {
          amount: listing?.fees?.core_breakdown?.fba_fulfillment_fee?.amount,
          tax: listing?.fees?.core_breakdown?.fba_fulfillment_fee?.tax,
          promotion:
            listing?.fees?.core_breakdown?.fba_fulfillment_fee?.promotion,
          final: listing?.fees?.core_breakdown?.fba_fulfillment_fee?.final,
        },
        perItem: {
          amount: listing?.fees?.core_breakdown?.per_item_fee?.amount,
          tax: listing?.fees?.core_breakdown?.per_item_fee?.tax,
          promotion: listing?.fees?.core_breakdown?.per_item_fee?.promotion,
          final: listing?.fees?.core_breakdown?.per_item_fee?.final,
        },
        closing: {
          amount: listing?.fees?.core_breakdown?.closing_fee?.amount,
          tax: listing?.fees?.core_breakdown?.closing_fee?.tax,
          promotion: listing?.fees?.core_breakdown?.closing_fee?.promotion,
          final: listing?.fees?.core_breakdown?.closing_fee?.final,
        },
      },
    };
    this.isBuyBoxWinner = listing?.is_buy_box_winner ?? null;
    this.isFeaturedMerchant = listing?.is_featured_merchant ?? null;
    this.offerPosition = listing?.offer_position ?? null;
    this.hasOffers = listing?.has_offers ?? null;
    this.hasNewBuyBox = listing?.has_new_buy_box ?? null;
    this.hasUsedBuyBox = listing?.has_used_buy_box ?? null;
    this.newBuyBox = listing?.new_buy_box?.price
      ? {
          price: listing?.new_buy_box?.price,
          shipping: listing?.new_buy_box?.shipping,
          sellerId: listing?.new_buy_box?.seller_id ?? null,
          isFba: listing?.new_buy_box?.is_fba ?? null,
          isPrime: listing?.new_buy_box?.is_prime ?? null,
          isNationalPrime: listing?.new_buy_box?.is_national_prime ?? null,
          feedbackCount: listing?.new_buy_box?.feedback_count ?? null,
          feedbackRating: listing?.new_buy_box?.feedback_rating ?? null,
          shippingAvailabilityType:
            listing?.new_buy_box?.shipping_availability_type ?? null,
          shippingAvailabilityTime: listing?.new_buy_box
            ?.shipping_availability_time
            ? new Date(listing?.new_buy_box?.shipping_availability_time)
            : null,
          shipFromCountry: listing?.new_buy_box?.ship_from_country ?? null,
        }
      : null;
    this.usedBuyBox = listing?.used_buy_box?.price
      ? {
          price: listing?.used_buy_box?.price,
          shipping: listing?.used_buy_box?.shipping,
          sellerId: listing?.used_buy_box?.seller_id ?? null,
          isFba: listing?.used_buy_box?.is_fba ?? null,
          isPrime: listing?.used_buy_box?.is_prime ?? null,
          isNationalPrime: listing?.used_buy_box?.is_national_prime ?? null,
          feedbackCount: listing?.used_buy_box?.feedback_count ?? null,
          feedbackRating: listing?.used_buy_box?.feedback_rating ?? null,
          shippingAvailabilityType:
            listing?.used_buy_box?.shipping_availability_type ?? null,
          shippingAvailabilityTime: listing?.used_buy_box
            ?.shipping_availability_time
            ? new Date(listing?.used_buy_box?.shipping_availability_time)
            : null,
          shipFromCountry: listing?.used_buy_box?.ship_from_country ?? null,
        }
      : null;
    this.competitivePriceThreshold =
      listing?.competitive_price_threshold ?? null;
    this.listPrice = listing?.list_price ?? null;
    this.suggestedLowerPricePlusShipping =
      listing?.suggested_lower_price_plus_shipping ?? null;
    this.classification = listing?.classification?.id
      ? {
          id: listing?.classification?.id,
          title: listing?.classification?.title,
          rank: listing?.classification?.rank,
        }
      : null;
    this.displayGroup = listing?.display_group?.id
      ? {
          id: listing?.display_group?.id,
          title: listing?.display_group?.title,
          rank: listing?.display_group?.rank,
        }
      : null;
  }

  getCondition() {
    switch (this.condition) {
      case "new_new":
        return "New";
      case "new_open_box":
        return "New (Open box)";
      case "new_oem":
        return "New (OEM)";
      case "refurbished_refurbished":
        return "Refurbished";
      case "used_like_new":
        return "Used (Like new)";
      case "used_very_good":
        return "Used (Very good)";
      case "used_good":
        return "Used (Good)";
      case "collectible_like_new":
        return "Collectible (Like new)";
      case "collectible_very_good":
        return "Collectible (Very good)";
      case "collectible_good":
        return "Collectible (Good)";
      case "collectible_acceptable":
        return "Collectible (Acceptable)";
      case "club_club":
        return "Club";
      default:
        return "Unknown";
    }
  }

  getFulfillmentType() {
    switch (this.fulfillmentType) {
      case "fba":
        return "FBA";
      case "mf":
        return "Merchant";
      default:
        return "Unknown";
    }
  }

  isNew() {
    switch (this.condition) {
      case "new_new":
        return true;
      case "new_open_box":
        return true;
      case "new_oem":
        return true;
      default:
        return false;
    }
  }

  hasBuyBoxData() {
    if (this.isNew() && this.hasNewBuyBox === null) {
      return false;
    }
    if (!this.isNew() && this.hasUsedBuyBox === null) {
      return false;
    }
    return true;
  }

  hasOfferData() {
    return this.hasOffers !== null;
  }

  hasAnyOffers() {
    return this.hasOffers === true;
  }

  isBuyBoxSuppressed() {
    if (this.isNew() && this.hasAnyOffers() && this.hasNewBuyBox === false) {
      return true;
    }
    if (!this.isNew() && this.hasAnyOffers() && this.hasUsedBuyBox === false) {
      return true;
    }
    return false;
  }

  getBuyBox() {
    if (!this.hasBuyBoxData) {
      throw new Error("no buy box data");
    }
    if (this.isNew()) {
      return this.newBuyBox as BuyBox;
    } else {
      return this.usedBuyBox as BuyBox;
    }
  }

  getBasicQuantity() {
    if (this.fulfillmentType === "fba") {
      return this.quantity.totalFba;
    } else {
      return this.quantity.mf;
    }
  }
}
